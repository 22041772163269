import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import AvatarProfile from "./avatarProfile.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2>{`Internal Design Library`}</h2>
    <p>{`Figma in the main tool in our team that allows designers to work in the same space. We have the Legion design library as a core to our UI-Kit, Foundation, Helper, Pattern, etc. for internal Telkom Indonesia designer (Chapter Design & Experience) and also many branches of design library in each of our products and brands under ecosystem or tribe which can be accessed by all Telkom Indonesia employees. To access such information, you can follow the guide in the `}<a parentName="p" {...{
        "href": "/design/getting-started/for-telkom-employee#access-request"
      }}>{`Access Request`}</a>{` section.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/design/get-started/libraryinter.png",
      "alt": "internal design library"
    }}></img>
    <br />
    <h2>{`Access Request`}</h2>
    <p>{`And each product is spread by tribe or ecosystem in a Figma project. So to access a file, project or you have just been assigned to a new product, you can fill in the `}<a parentName="p" {...{
        "href": "https://forms.gle/vNvoJ5t6FDM7D1VD8"
      }}>{`request form`}</a>{` with following steps below.`}</p>
    <div {...{
      "className": "row1"
    }}>{`
  `}<div parentName="div" {...{
        "className": "double-column1"
      }}>{`
    `}<img parentName="div" {...{
          "className": "imageleft",
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/design/get-started/access-request.png",
          "alt": "access request"
        }}></img>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "column1",
        "style": {
          "justifyContent": "flex-start"
        }
      }}>{`
    `}<ul parentName="div">{`
      `}<li parentName="ul">{`Fill in the tools field that you want to request, make sure you select Figma`}</li>{`
      `}<li parentName="ul">{`Enter your assigned Tribe Name`}</li>{`
      `}<li parentName="ul">{`Then select the required tools according to what you need`}</li>{`
    `}</ul>{`
    `}<br parentName="div"></br>{`
  `}</div>
    </div>
    <p>{`Requester will receive an email notification in the form of a link and can access the requested Figma`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      